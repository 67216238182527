import React from 'react'
import Footer from '../../../Components/Footer'
import Headernew from '../../../Components/Headernew'
import Myinput from '../../../Components/Myinput'

const Mainregister = () => {
  return (
    <div className="bg-white flex flex-col items-stretch pr-4">
      <Headernew/>
      <div className="bg-indigo-700 flex shrink-0 h-px flex-col max-md:max-w-full" />
      <div className="bg-gray-200 flex flex-col justify-center items-center mt-2.5 px-16 py-11 max-md:max-w-full max-md:px-5">
        <div className="shadow-sm bg-white flex w-[390px] max-w-full flex-col mb-1.5 px-3 py-11">
          <div className="text-zinc-800 text-center mb-3 text-3xl leading-9 capitalize self-center whitespace-nowrap">
            Join And Earn Cashback
          </div>
          <Myinput placeholder={'Eneter username'}/>
          
          <div className="bg-zinc-300 self-stretch flex shrink-0 h-0.5 flex-col mt-4" />
          <Myinput placeholder={'Eneter Password'}/>
         
          <div className="bg-zinc-300 self-stretch flex shrink-0 h-0.5 flex-col mt-4" />
          <Myinput placeholder={'Eneter Password'}/>
<div className="text-white text-center text-base leading-5 uppercase whitespace-nowrap shadow-lg bg-yellow-400 self-stretch justify-center items-center mt-12 px-16 py-5 rounded-3xl max-md:mt-10 max-md:px-5">
            Join Now
          </div>
          <div className="self-center flex items-stretch justify-between gap-5 mt-6">
            <div className="text-neutral-400 text-base leading-6">
              Already have an account?
            </div>
            <div className="text-orange-600 text-base leading-6">
              Signin Now
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Mainregister