import React from 'react'

const Myinput = ({placeholder}) => {
  return (
    <div className='w-full flex justify-center m-2'>
         <input placeholder={placeholder} className="text-black focus:outline-none text-sm w-[95%] leading-5 self-stretch"/>
                           
                        
    </div>
  )
}

export default Myinput