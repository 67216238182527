import React from 'react'
import Footer from '../../Components/Footer'
import Header from '../../Components/Header'
import Headernew from '../../Components/Headernew'

const Mainhome = () => {
    return (
        <div className="bg-[#edecec] flex flex-col">


            {/* <Header/> */}

            <Headernew/>



            <div className="bg-indigo-700 self-stretch flex min-h-[1px] w-full flex-col max-md:max-w-full" />
            <div className="self-stretch z-[1] w-full px-5 max-md:max-w-full mt-5">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className=" flex flex-col items-stretch w-[31%] max-md:w-full max-md:ml-0">
                        <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288659/cashpakar/telegram_1597024833_y3bcxw.jpg' className=" flex w-[400px] shrink-0 max-w-full h-[189px] flex-col mx-auto rounded-md" />
                    </div>
                    <div className="md:block hidden flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                        <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288659/cashpakar/a2hosting_1597024839_j4ijoy.jpg' className=" flex w-[400px] shrink-0 max-w-full h-[189px] flex-col mx-auto rounded-md" />
                    </div>
                    <div className="md:block hidden flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                        <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288659/cashpakar/norton_1597024665_rsft9t.jpg' className=" flex w-[400px] shrink-0 max-w-full h-[189px] flex-col mx-auto rounded-md" />
                    </div>
                </div>
            </div>
            <div className="text-white text-center md:text-4xl leading-10 whitespace-nowrap bg-indigo-700 self-center w-full text-md justify-center items-center mt-10 px-16 py-3.5 max-md:max-w-full max-md:px-5">
                India's Best Coupon & Cashback Site
            </div>{" "}
            <div className="text-neutral-400 text-center text-md md:text-xl leading-6 self-center whitespace-nowrap mt-2.5">
                Whatever you Buy, we pay you Extra Cashback.
            </div>{" "}
            <div className="self-center w-full max-w-[1074px] mt-7 px-5 max-md:max-w-full">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400  flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex justify-between gap-5 pl-5 pr-14 py-5 rounded-lg items-start max-md:pr-5">
                                <div className="text-black text-start text-sm leading-5 capitalize mt-4">
                                    Cameras & Accessories
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288659/cashpakar/cameras-accessories_1597024370_w7hqb0.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-6 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-sm leading-5 capitalize grow whitespace-nowrap my-auto">
                                    Electronics
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/electronics_1597024377_a5yse2.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-8 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-base leading-5 capitalize grow whitespace-nowrap my-auto">
                                    Fashion
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/fashion_1597024384_nu7fwm.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-11 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-sm leading-5 capitalize grow whitespace-nowrap my-auto">
                                    Food
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288657/cashpakar/food_1597024390_hneyes.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>
                </div>
            </div>{" "}
            <div className="self-center w-full max-w-[1074px] mt-7 px-5 max-md:max-w-full">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400  flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex justify-between gap-5 pl-5 pr-14 py-5 rounded-lg items-start max-md:pr-5">
                                <div className="text-black text-start text-sm leading-5 capitalize mt-4">
                                    Footwear
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288657/cashpakar/footwear_1597024395_oavf2o.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-6 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-sm leading-5 capitalize grow whitespace-nowrap my-auto">
                                    Travel
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/travel_1597024401_zeosyv.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-8 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-base leading-5 capitalize grow whitespace-nowrap my-auto">
                                    Install & Earn
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/install-earn_1597024407_cpgqn4.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex grow flex-col items-stretch max-md:mt-8">
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col" />{" "}
                            <div className="shadow-sm bg-white flex items-center justify-between gap-5 pl-11 pr-14 py-5 rounded-lg max-md:px-5">
                                <div className="text-black text-start text-sm leading-5 capitalize grow whitespace-nowrap my-auto">
                                    More..
                                </div>{" "}
                                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/plus2_zexpia.jpg' className=" self-stretch flex w-[52px] shrink-0 h-[52px] flex-col" />
                            </div>{" "}
                            <div className="bg-cyan-400 flex shrink-0 h-[3px] flex-col mt-2" />
                        </div>
                    </div>
                </div>
            </div>{" "}


            <div className="bg-indigo-700 self-stretch w-full pl-4 pr-8 pt-12 pb-6 max-md:max-w-full max-md:pr-5">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[68%] max-md:w-full max-md:ml-0">
                        <div className="shadow-2xl bg-white flex flex-col items-center w-full mt-2.5 pl-16 pr-20 py-9 rounded max-md:max-w-full max-md:mt-10 max-md:px-5">
                            <div className="z-[1] flex w-[547px] max-w-full items-stretch justify-between gap-5 max-md:flex-wrap max-md:justify-center">
                                <div className="bg-indigo-500 flex shrink-0 h-[3px] flex-col flex-1" />{" "}
                                <div className="bg-indigo-500 flex shrink-0 h-[3px] flex-col flex-1" />{" "}
                                <div className="bg-indigo-500 flex shrink-0 h-[3px] flex-col flex-1" />
                            </div>{" "}
                            <div className="w-full self-start max-md:max-w-full">
                                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                    <div className="flex flex-col items-stretch w-2/5 max-md:w-full max-md:ml-0">
                                        <div className="z-[1] flex grow flex-col items-center">
                                            <img
                                                loading="lazy"
                                                srcSet="https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/1502529598_myvishal_rfhqnw.jpg"
                                                className="aspect-[2.46] object-contain object-center w-full border overflow-hidden self-stretch border-solid border-gray-200"
                                            />{" "}
                                            <div className="bg-indigo-500 flex w-[157px] shrink-0 h-[3px] flex-col mt-1.5" />{" "}
                                            <img
                                                loading="lazy"
                                                srcSet="https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/19703-21414136dc4ca61f_vefb45.png"
                                                className="aspect-[2.46] object-contain object-center w-full border overflow-hidden self-stretch border-solid border-gray-200"
                                            />{" "}
                                            <div className="bg-indigo-500 flex w-[157px] shrink-0 h-[3px] flex-col mt-1" />{" "}
                                            <img
                                                loading="lazy"
                                                srcSet="https://res.cloudinary.com/dhiare7bl/image/upload/v1702355134/opengraph_cg5euy.png"
                                                className="aspect-[2.46] object-contain object-center w-full border overflow-hidden self-stretch border-solid border-gray-200"
                                            />
                                        </div>
                                    </div>{" "}
                                    <div className="flex flex-col items-stretch w-[31%] ml-5 max-md:w-full max-md:ml-0">
                                        <div className="flex grow flex-col items-center">
                                            <div className="border bg-white self-stretch flex flex-col justify-center items-stretch px-4 py-px border-solid border-gray-200">
                                                <div className="shadow-sm bg-white flex shrink-0 h-[100px] flex-col rounded-lg" />
                                            </div>{" "}
                                            <div className="bg-indigo-500 z-[1] flex w-[157px] shrink-0 h-[3px] flex-col mt-1.5" />{" "}
                                            <div className="border bg-white self-stretch flex flex-col justify-center items-stretch px-4 py-px border-solid border-gray-200">
                                                <div className="shadow-sm bg-white flex shrink-0 h-[100px] flex-col rounded-lg" />
                                            </div>{" "}
                                            <div className="bg-indigo-500 z-[1] flex w-[157px] shrink-0 h-[3px] flex-col mt-1" />{" "}
                                            <div className="border bg-white self-stretch flex flex-col justify-center items-stretch px-4 py-px border-solid border-gray-200">
                                                <div className="shadow-sm bg-white flex shrink-0 h-[100px] flex-col rounded-lg" />
                                            </div>
                                        </div>
                                    </div>{" "}
                                    <div className="flex flex-col items-stretch w-[30%] ml-5 max-md:w-full max-md:ml-0">
                                        <div className="border bg-white flex gap-0 w-full pr-4 py-px border-solid border-gray-200 items-start">
                                            <div className="flex basis-[0%] flex-col items-stretch mt-8">
                                                <div className="text-white text-base leading-5 capitalize whitespace-nowrap">
                                                    Show All
                                                </div>{" "}
                                                <div className="text-white text-xs leading-4 whitespace-nowrap mt-5">
                                                    1 OFFERS
                                                </div>
                                            </div>{" "}
                                            <div className="shadow-sm bg-white self-stretch flex w-[157px] shrink-0 h-[100px] flex-col rounded-lg" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-[32%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex flex-col items-stretch mt-8 max-md:mt-10">
                            <div className="text-white text-2xl leading-7 whitespace-nowrap">
                                Popular Stores
                            </div>{" "}
                            <div className="bg-white flex shrink-0 h-px flex-col mt-4" />{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-3.5">
                                View All
                            </div>
                        </div>
                    </div>
                </div>
            </div>{" "}
            <div className="text-zinc-800 text-center text-xl leading-6 capitalize whitespace-nowrap shadow-sm bg-white self-center w-[630px] max-w-full justify-center items-center mt-9 px-16 py-3.5 rounded-[50px] max-md:px-5">
                Fashion Offers & Coupons
            </div>{" "}
            <div className="bg-white self-center flex w-[207px] max-w-full flex-col mt-4 px-3 py-6">
                <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/1502529598_myvishal_rfhqnw.jpg' className="rounded self-stretch flex shrink-0 h-[76px] flex-col" />{" "}
                <div className="text-black text-center text-sm leading-5 capitalize self-stretch mt-4">
                    Flash Sale Shop 249 Or Above And Get Flat 200Rs CP Cashback+ 10% Off
                    Coupon Code
                </div>{" "}
                <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-4">
                    See All coupons/deals
                </div>{" "}
                <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                    Show Code
                </div>{" "}
                <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
            </div>{" "}
            <div className="text-zinc-800 text-center text-xl leading-6 capitalize whitespace-nowrap shadow-sm bg-white self-center w-[630px] max-w-full justify-center items-center mt-5 px-16 py-3.5 rounded-[50px] max-md:px-5">
                Hosting & Domains Offers & Coupons
            </div>{" "}
            <div className="self-center w-[453px] max-w-full mt-5">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-6/12 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/19703-21414136dc4ca61f_vefb45.png' className="rounded  self-stretch flex shrink-0 h-[71px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize mt-5">
                                Get .IN domain @ Rs139/Yr when brought for 2years.
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-4">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-6/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702355134/opengraph_cg5euy.png' className="rounded  self-stretch flex shrink-0 h-[49px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize self-stretch mt-11 max-md:mt-10">
                                Web Hosting 1 Year @51% One Time Discount + 4000 RS CP CashBack
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-5">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>
                </div>
            </div>{" "}
            <div className="text-zinc-800 text-center text-xl leading-6 capitalize whitespace-nowrap shadow-sm bg-white self-center w-[630px] max-w-full justify-center items-center mt-5 px-16 py-3.5 rounded-[50px] max-md:px-5">
                Baby Clothes Offers & Coupons
            </div>{" "}
            <div className="self-center w-full max-w-[945px] mt-4 px-5 max-md:max-w-full">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/firstcry_ehuknh.png' className="rounded  self-stretch flex shrink-0 h-[71px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize mt-5">
                                Get Flat Rs 400 OFF* on minimum purchase of Rs 1150
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-5">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/firstcry_ehuknh.png' className="rounded  self-stretch flex shrink-0 h-[71px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize mt-5">
                                Flat Rs 800 OFF* on minimum purchase of Rs 2200
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-5">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/firstcry_ehuknh.png' className="rounded  self-stretch flex shrink-0 h-[71px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize mt-5">
                                Get Flat Rs 700 OFF* on minimum purchase of Rs 2000
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-5">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0">
                        <div className="bg-white flex grow flex-col w-full px-3 py-6 max-md:mt-10">
                            <img src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/firstcry_ehuknh.png' className="rounded  self-stretch flex shrink-0 h-[71px] flex-col" />{" "}
                            <div className="text-black text-center text-sm leading-5 capitalize mt-5">
                                Get Flat Rs 350 OFF* on minimum purchase of Rs 1050
                            </div>{" "}
                            <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-5">
                                See All coupons/deals
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                                Show Code
                            </div>{" "}
                            <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
                        </div>
                    </div>
                </div>
            </div>{" "}
            <div className="text-zinc-800 text-center text-xl leading-6 capitalize max-w-full shadow-sm bg-white self-center w-[630px] justify-center items-center mt-5 px-16 py-3.5 rounded-[50px] max-md:px-5">
                Hotels & Restaurants Offers & Coupons
            </div>{" "}
            <div className="bg-white self-center flex w-[207px] max-w-full flex-col mt-5 px-3 py-6">
                <img src='https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/goibibo-hotels-1627380915_xphabd.png' className="rounded  self-stretch flex shrink-0 h-[65px] flex-col" />{" "}
                <div className="text-black text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-7">
                    35% off on all properties
                </div>{" "}
                <div className="text-indigo-400 text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-4">
                    See All coupons/deals
                </div>{" "}
                <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-16 max-md:mt-10 max-md:mx-1" />{" "}
                <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-stretch justify-center items-stretch mt-2.5 px-12 py-3 max-md:mx-1 max-md:px-5">
                    Show Code
                </div>{" "}
                <div className="bg-zinc-500 self-stretch flex w-full shrink-0 h-0.5 flex-col mt-2.5 max-md:mx-1" />
            </div>{" "}

            <Footer />

        </div>
    )
}

export default Mainhome



