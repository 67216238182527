import React from 'react'

const Footer = () => {
  return (
    <div>
        <div className="bg-neutral-800 self-stretch w-full mt-8 pt-7 pb-12 px-8 max-md:max-w-full max-md:px-5">
                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                    <div className="flex flex-col items-stretch w-[34%] max-md:w-full max-md:ml-0">
                        <div className="flex flex-col items-start max-md:mt-10">
                            <div className="text-white text-sm leading-4 self-stretch whitespace-nowrap">
                                Cashpakar
                            </div>{" "}
                            <div className="bg-slate-400 flex w-[72px] shrink-0 h-px flex-col mt-7" />{" "}
                            <div className="text-white text-sm leading-5 self-stretch mt-6">
                                Cashpakar is that platform where you can earn extra cashback
                                when you shop through cashpakar on 250+ shopping site in India.
                            </div>
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-[24%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex flex-col items-stretch max-md:mt-10">
                            <div className="text-white text-sm leading-4 whitespace-nowrap">
                                Top Stores
                            </div>{" "}
                            <div className="bg-slate-400 flex shrink-0 h-px flex-col mt-7" />{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-6">
                                PowerPlay Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
                                BetandYou Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
                                JeetPlay Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
                                BetoBet Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
                                CaptainsBet Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
                                OhMySpins Casino
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
                                CampeonBet Casino
                            </div>
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-[24%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex flex-col items-stretch max-md:mt-10">
                            <div className="text-white text-sm leading-4 whitespace-nowrap">
                                Usefull Links
                            </div>{" "}
                            <div className="bg-slate-400 flex shrink-0 h-px flex-col mt-8" />{" "}
                            <a href='/about-us' className="text-white cursor-pointer text-sm leading-5 whitespace-nowrap mt-6">
                                About Us
                            </a>{" "}
                            <a href='/privacy-policy' className="text-white cursor-pointer text-sm leading-5 whitespace-nowrap mt-2.5">
                                Privacy Policy
                            </a>{" "}

                          


                            <a href='/terms-and-conditions' className="text-white cursor-pointer text-sm leading-5 whitespace-nowrap mt-2">
                                Terms and Conditions
                            </a>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-3">
                                FAQ
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
                                How it Work
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
                                Cashback Releted
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
                                Refer And Earn
                            </div>{" "}
                            <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
                                Gwalax Course
                            </div>
                        </div>
                    </div>{" "}
                    <div className="flex flex-col items-stretch w-[17%] ml-5 max-md:w-full max-md:ml-0">
                        <div className="flex flex-col max-md:mt-10">
                            <div className="text-white text-sm leading-4 self-stretch whitespace-nowrap">
                                Contact Us
                            </div>{" "}
                            <div className="bg-slate-400 self-stretch flex shrink-0 h-px flex-col mt-8" />{" "}
                            <div className="text-cyan-600 text-sm leading-5 self-stretch mt-6">
                                Telegram - @Cashpakar_Support
                            </div>{" "}
                            <div className="text-cyan-600 text-sm leading-5 self-stretch whitespace-nowrap mt-4">
                                info@cashpakar.com
                            </div>{" "}
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/88cacc6fc2c57447df7f65bb2acb031b5161357be69adfabe809924330775601?apiKey=39ad5d3b843547288fd1e2c2a611a66c&"
                                className="aspect-[5.32] object-contain object-center w-[165px] items-start overflow-hidden self-center mt-3.5"
                            />
                        </div>
                    </div>
                </div>
            </div>{" "}
            <div className="text-neutral-400 text-center text-sm leading-5 whitespace-nowrap bg-neutral-800 self-stretch w-full justify-center items-center px-16 py-2.5 max-md:max-w-full max-md:px-5">
                Copyright@2024 CashPakar.com
            </div>
    </div>
  )
}

export default Footer