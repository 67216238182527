import React from 'react'
import Headernew from '../../../Components/Headernew'
import Footer from '../../../Components/Footer'
import Mybtn from '../../../Components/Mybtn'
import Myinput from '../../../Components/Myinput'

const Mainlogin = () => {
    return (
        <div className="bg-white flex flex-col ">
            <Headernew/>
            <div className="shadow-sm  bg-gray-200 z-[1] flex flex-col justify-center items-center  px-16 py-12 max-md:max-w-full max-md:px-5">
                <div className="shadow-sm bg-white flex w-[390px] max-w-full flex-col items-center mb-4 py-12">
                    <div className="text-zinc-800 text-center text-3xl leading-9 capitalize whitespace-nowrap">
                        Signin
                    </div>
                    <div className="self-stretch flex flex-col mt-28 px-3 max-md:mt-10">
                       <Myinput placeholder={'Enter email id'}/>
                        <div className="bg-zinc-300 self-stretch flex shrink-0 h-px flex-col mt-4" />
                        <div className="text-pink-800 text-sm leading-5 self-stretch mt-3 max-md:mt-10">
                            <Myinput placeholder={'enter password'}/>
                        </div>
                        <div className="bg-zinc-300 self-stretch flex shrink-0 h-0.5 flex-col mt-4" />
                        <div className="flex items-stretch gap-3 ml-14 mt-11 self-start max-md:ml-2.5 max-md:mt-10">
                            <div className="text-neutral-400 text-base leading-6 grow whitespace-nowrap">
                                Forgot My
                            </div>
                            <div className="text-orange-600 text-base leading-6 grow whitespace-nowrap self-start">
                                Password?
                            </div>
                        </div>
                    </div>
                    <Mybtn/>
                    <div className="flex items-stretch justify-between gap-5 mt-11 mb-2.5 max-md:mt-10">
                        <div className="text-neutral-400 text-base leading-6">
                            Don’t have an account?
                        </div>
                        <div className="text-orange-600 text-base leading-6">Join Now</div>
                    </div>
                </div>
            </div>
           <Footer/>
        </div>
    )
}

export default Mainlogin