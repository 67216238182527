import React from 'react'
import Footer from '../Components/Footer'
import Headernew from '../Components/Headernew'

const Termscondition = () => {
  return (
    <div className="bg-white flex flex-col items-stretch">
     <Headernew/>
      <div className="bg-indigo-700 flex w-[1263px] shrink-0 max-w-full h-px flex-col mr-4 max-md:mr-2.5" />
      <div className="flex w-full flex-col mt-7 px-16 max-md:max-w-full max-md:px-5">
        <div className="text-zinc-800 text-4xl leading-10 self-stretch max-md:max-w-full">
          Terms and Conditions
        </div>
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-9 max-md:max-w-full">
          Terms and Conditions
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
          Welcome to CashPakar!
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          These terms and conditions outline the rules and regulations for the
          use of Cashpakar's Website, located at www.cashpakar.com.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          By accessing this website we assume you accept these terms and
          conditions. Do not continue to use CashPakar if you do not agree to
          take all of the terms and conditions stated on this page.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          The following terminology applies to these Terms and Conditions,
          Privacy Statement and Disclaimer Notice and all Agreements:
          &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to
          you, the person log on this website and compliant to the Company’s
          terms and conditions. &quot;The Company&quot;, &quot;Ourselves&quot;,
          &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to our
          Company. &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;,
          refers to both the Client and ourselves. All terms refer to the offer,
          acceptance and consideration of payment necessary to undertake the
          process of our assistance to the Client in the most appropriate manner
          for the express purpose of meeting the Client’s needs in respect of
          provision of the Company’s stated services, in accordance with and
          subject to, prevailing law of Netherlands. Any use of the above
          terminology or other words in the singular, plural, capitalization
          and/or he/she or they, are taken as interchangeable and therefore as
          referring to same.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Cookies
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
          We employ the use of cookies. By accessing CashPakar, you agreed to
          use cookies in agreement with the Cashpakar's Privacy Policy.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          Most interactive websites use cookies to let us retrieve the user’s
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </div>
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          License
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-6 mx-5 max-md:max-w-full max-md:mr-2.5">
          Unless otherwise stated, Cashpakar and/or its licensors own the
          intellectual property rights for all material on CashPakar. All
          intellectual property rights are reserved. You may access this from
          CashPakar for your own personal use subjected to restrictions set in
          these terms and conditions.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          You must not:
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-5 self-start max-md:ml-2.5">
          Republish material from CashPakar
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-1.5 self-start max-md:ml-2.5">
          Sell, rent or sub-license material from CashPakar
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          Reproduce, duplicate or copy material from CashPakar
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-1.5 self-start max-md:ml-2.5">
          Redistribute content from CashPakar
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          This Agreement shall begin on the date hereof.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          Cashpakar does not filter, edit, publish or review Comments prior to
          their presence on the website. Comments do not reflect the views and
          opinions of Cashpakar,its agents and/or affiliates. Comments reflect
          the views and opinions of the person who post their views and
          opinions. To the extent permitted by applicable laws, Cashpakar shall
          not be liable for the Comments or for any liability, damages or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-4 mx-7 max-md:max-w-full max-md:mr-2.5">
          Cashpakar reserves the right to monitor all Comments and to remove any
          Comments which can be considered inappropriate, offensive or causes
          breach of these Terms and Conditions.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          You warrant and represent that:
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-4 self-start max-md:max-w-full">
          You are entitled to post the Comments on our website and have all
          necessary licenses and consents to do so;
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          The Comments do not invade any intellectual property right, including
          without limitation copyright, patent or trademark of any third party;
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          The Comments do not contain any defamatory, libelous, offensive,
          indecent or otherwise unlawful material which is an invasion of
          privacy
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          The Comments will not be used to solicit or promote business or custom
          or present commercial activities or unlawful activity.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          You hereby grant Cashpakar a non-exclusive license to use, reproduce,
          edit and authorize others to use, reproduce and edit any of your
          Comments in any and all forms, formats or media.
        </div>
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Hyperlinking to our Content
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          The following organizations may link to our Website without prior
          written approval:
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-4 self-start max-md:ml-2.5">
          Government agencies;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          Search engines;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          News organizations;
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          Online directory distributors may link to our Website in the same
          manner as they hyperlink to the Websites of other listed businesses;
          and
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-1.5 mx-8 max-md:max-w-full max-md:mr-2.5">
          System wide Accredited Businesses except soliciting non-profit
          organizations, charity shopping malls, and charity fundraising groups
          which may not hyperlink to our Web site.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-5 mx-7 max-md:max-w-full max-md:mr-2.5">
          These organizations may link to our home page, to publications or to
          other Website information so long as the link: (a) is not in any way
          deceptive; (b) does not falsely imply sponsorship, endorsement or
          approval of the linking party and its products and/or services; and
          (c) fits within the context of the linking party’s site.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          We may consider and approve other link requests from the following
          types of organizations:
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-4 self-start max-md:ml-2.5">
          commonly-known consumer and/or business information sources;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          dot.com community sites;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          associations or other groups representing charities;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          online directory distributors;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          internet portals;
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-1.5 self-start max-md:ml-2.5">
          accounting, law and consulting firms; and
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-2 self-start max-md:ml-2.5">
          educational institutions and trade associations.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full ml-3.5 mr-3.5 mt-5 max-md:max-w-full max-md:mr-2.5">
          We will approve link requests from these organizations if we decide
          that: (a) the link would not make us look unfavorably to ourselves or
          to our accredited businesses; (b) the organization does not have any
          negative records with us; (c) the benefit to us from the visibility of
          the hyperlink compensates the absence of Cashpakar; and (d) the link
          is in the context of general resource information.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-4 mx-3.5 max-md:max-w-full max-md:mr-2.5">
          These organizations may link to our home page so long as the link: (a)
          is not in any way deceptive; (b) does not falsely imply sponsorship,
          endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party’s site.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          If you are one of the organizations listed in paragraph 2 above and
          are interested in linking to our website, you must inform us by
          sending an e-mail to Cashpakar. Please include your name, your
          organization name, contact information as well as the URL of your
          site, a list of any URLs from which you intend to link to our Website,
          and a list of the URLs on our site to which you would like to link.
          Wait 2-3 weeks for a response.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          Approved organizations may hyperlink to our Website as follows:
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-4 self-start max-md:ml-2.5">
          By use of our corporate name; or
        </div>
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-1.5 self-start max-md:ml-2.5">
          By use of the uniform resource locator being linked to; or
        </div>
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          By use of any other description of our Website being linked to that
          makes sense within the context and format of content on the linking
          party’s site.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          No use of Cashpakar's logo or other artwork will be allowed for
          linking absent a trademark license agreement.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          iFrames
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 mx-6 max-md:max-w-full max-md:mr-2.5">
          Without prior approval and written permission, you may not create
          frames around our Webpages that alter in any way the visual
          presentation or appearance of our Website.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Content Liability
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          We shall not be hold responsible for any content that appears on your
          Website. You agree to protect and defend us against all claims that is
          rising on your Website. No link(s) should appear on any Website that
          may be interpreted as libelous, obscene or criminal, or which
          infringes, otherwise violates, or advocates the infringement or other
          violation of, any third party rights.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Your Privacy
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          Please read Privacy Policy
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Reservation of Rights
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amen
          these terms and conditions and it’s linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-6 max-md:max-w-full">
          Removal of links from our website
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-6 mx-5 max-md:max-w-full max-md:mr-2.5">
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </div>{" "}
        <div className="text-zinc-800 text-2xl leading-7 self-stretch mt-5 max-md:max-w-full">
          Disclaimer
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties and conditions relating to our website and
          the use of this website. Nothing in this disclaimer will:
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-5 self-start max-md:ml-2.5">
          limit or exclude our or your liability for death or personal injury;
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 ml-10 mt-1.5 self-start max-md:max-w-full">
          exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort and for
          breach of statutory duty.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </div>
      </div>{" "}
     <Footer/>
    </div>
  )
}

export default Termscondition