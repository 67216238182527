
import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";

// import { ToastContainer, toast } from 'react-toastify';

import Mainlogin from '../Login/Mainlogin';
import Mainhome from '../../Home/Mainhome';
import Mainstore from '../Stores/Mainstore';
import Viewstore from '../Stores/Viewstore';
import Privacypolicy from '../../Privacypolicy';
import Termscondition from '../../Termscondition';
import Aboutus from '../../Aboutus';
import Mainregister from '../Register/Mainregister';


const Mainroutesuser = () => {
  const token = localStorage.getItem('token')

  const [isvalid,setisvalid] = useState(null)
  const [loading,setloading] = useState(false)
  

  // const {platformurl} = useParams()
  
  
  const [info,setinfo] = useState(null)
  
  const [platformurl,setplatformname] = useState()

//   useEffect(()=>{
//     setplatformname(getSubdomain(window.location.host))
//   },[])

//   useEffect(()=>{
//     platformurl && callForCheckPlatform()
//     platformurl && token && checkvalidtoken()
    
//   },[platformurl])




//   const callForCheckPlatform =async ()=>{
//     setloading(true)
//     await gpeapi.get(`/${platformurl}/checkagencyplatform`).then((res)=>setisvalid(res.data.success)).catch((e)=>setisvalid(e?.response?.data?.success))
//     setloading(false)
//   }




//   const checkvalidtoken = ()=>{
//     gpeapi.get(`/${platformurl}/checkagencytoken`,{headers:{
//       "Authorization":`Beaer ${token}`
//   }}).then((res)=>{
//     if(res.data.success){
//       setinfo(res.data.user)
      
//        localStorage.setItem('userInfo', JSON.stringify(res.data.user));

       

//        if(res.data.user?.extendplanended){
//         toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
//        }

//        if(res.data.user?.planended){
//         toast.warning('Your Plan Ended Please Purchase a Plan to Continue Services')
//        }

      
     
//     }else{
//       localStorage.clear()
//       window.location.reload('')
//     }
//   })
//   // console.log('runnn')
//   }

//   const menus = [
//     { name: "dashboard", link: `/agency/dashboard`, icon: MdOutlineDashboard },
//     {
//       name: "campaigns", link: "/", icon: MdCampaign, submenu: [
//         { name: "all campaigns", link: `/agency/campaigns` },
//         { name: "create campaign", link: `/agency/createcamp` },
        
//       ]
//     },
//     {
//       name: "reports", link: "/", icon: IoAnalyticsSharp, submenu: [
//         { name: "campaigns report", link: `/agency/performancerepo` },
//         { name: "daily report", link: `/agency/dailyreport` },
//         { name: "publishers report", link: `/agency/publisherreport` },
//         { name: "advertisers report", link: `/agency/advertiserreport` },
//         { name: "conversion report", link: `/agency/conversionreport` },
//         { name: "event report", link: `/agency/eventreport` },
//         // { name: "clicks report", link: "/" },
//         // { name: "sub-id reports", link: "/" },
//       ]
//     },
//     { name: "publishers", link: "/", icon: TbFriends,submenu:[
//       { name: "all publishers", link: `/agency/publishers` },
      

//     ] },
//     { name: "advertisers", link: "/", icon: FaHandsHelping,submenu:[
//       { name: "all advertisers", link: `/agency/advertisers` },
      

//     ]  },

//     { name: "employees", link: "/", icon: BiSolidUserCheck,submenu:[
//       { name: "all employees", link: `/agency/employees` },
      

//     ]  },

//     { name: "publishers invoice", link: "/", icon: FaFileInvoiceDollar , submenu: [
//       { name: "manage invoices", link:  `/agency/manageinvoice`},
//       // { name: "create invoice", link:  `/agency/createinvoice` },
      
//   ]},

//     { name: "tools", link: "/", icon: PiToolboxFill,submenu:[
//       { name: "mailer", link: `/agency/mailer` },
//       { name: "send notification", link: `/agency/sendnotification` },
//       { name: "link tester", link: `/agency/linktester` },
      

//     ]  },

//     { name: "setting", link: "/", icon: IoIosSettings,submenu:[
//       { name: "SMTP Setup", link: `/agency/smtp_setup` },
      

//     ]  },



//     { name: "account", link: `/agency/account`, icon: CgProfile 
//   },

//   { name: "billing", link: `/agency/billing`, icon: MdPayment 
//   },
//     // { name: "Cart", link: "/", icon: FiShoppingCart },
//     // { name: "Billing", link: "/", icon: TbCreditCard },
//     // { name: "Setting", link: "/", icon: RiSettings4Line },
//   ];

  // console.log(info)


  return (

    // isvalid==null?<Spinner style={{display:'none'}}/>:isvalid 
    // ?
    <>

    {/* <ToastContainer/> */}

    

  


      <Routes>

        
            <>
              <Route path='/*' element={<Mainhome />} />
              <Route path='/' element={<Mainhome />} />
              <Route path='/all-store' element={<Mainstore />} />
              <Route path='/view-store' element={<Viewstore />} />
              <Route path='/privacy-policy' element={<Privacypolicy />} />
              <Route path='/terms-and-conditions' element={<Termscondition />} />
              <Route path='/about-us' element={<Aboutus />} />

              <Route path='/login' element={<Mainlogin />} />
              <Route path='/register' element={<Mainregister />} />

             






            


            </>

        

        {/* <Route path='/home' element={<Mainhome/>}/> */}

        {/* <Route path='/register' element={<Mainregister/>}/> */}


      </Routes>
      

    </>
    // :
    // // <Spinner/>
    // <Page404Found/>







  )
}

export default Mainroutesuser


