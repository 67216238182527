import React from 'react'

const Mybtn = ({title,icon,onClick,width,bgcolor,color,loading,margin,classname,padding,border,radius,bordercolor,fontsize}) => {
  return (
    <div className='flex justify-center w-full'>
        <div className="text-white text-center flex self-center text-lg font-semibold leading-5 uppercase whitespace-nowrap shadow-lg bg-yellow-400  w-[90%] justify-center  items-center  mt-6  py-4 rounded-3xl ">
    Signin Now
</div>
    </div>
  )
}

export default Mybtn