import React from 'react'
import Headernew from '../../../Components/Headernew'
import Storecard from '../../../Components/Storecard'
import Footer from '../../../Components/Footer'

const Mainstore = () => {


    const storedta = [
        {
            img:'https://www.a2hosting.com/images/2015/logo.png',
            offers:1
        },
        {
            img:'https://www.a2hosting.com/images/2015/logo.png',
            offers:2
        },
        {
            img:'https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/firstcry_ehuknh.png',
            offers:0
        },
        {
            img:'https://res.cloudinary.com/dhiare7bl/image/upload/v1702355134/opengraph_cg5euy.png',
            offers:3
        },
        {
            img:'https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/19703-21414136dc4ca61f_vefb45.png',
            offers:0
        },
        {
            img:'https://res.cloudinary.com/dhiare7bl/image/upload/v1702288658/cashpakar/1502529598_myvishal_rfhqnw.jpg',
            offers:0
        },
        {
            img:'https://res.cloudinary.com/dcpn2tcwm/image/upload/v1670502282/goibibo-hotels-1627380915_xphabd.png',
            offers:0
        },
    ]
    return (
        <div className="flex flex-col items-stretch ">
            <Headernew />
            <div className="bg-indigo-700 flex shrink-0 h-px flex-col max-md:max-w-full" />
            <div className="bg-white flex flex-col jus items-stretch mt-3 mx-10 pl-16 pr-9 py-6 rounded-md max-md:max-w-full max-md:mr-2.5 max-md:px-5">
                <div className="flex  gap-1 max-md:max-w-full max-md:flex-wrap">
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.1944444444444444] justify-center items-stretch px-4 py-3.5 rounded border-solid border-zinc-300">
                        all
                    </div>
                    <div className="text-white text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-cyan-600 aspect-[1.0277777777777777] justify-center items-center h-[37px] px-3.5 border-solid border-cyan-600">
                        A
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        B
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        C
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-4 py-3.5 border-solid border-zinc-300">
                        D
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9722222222222222] justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        E
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9722222222222222] justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        F
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0277777777777777] justify-center items-center h-[37px] px-4 border-solid border-zinc-300">
                        G
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-4 py-3.5 border-solid border-zinc-300">
                        H
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide border bg-white aspect-[0.8333333333333334] justify-center items-stretch px-4 py-3.5 border-solid border-zinc-300">
                        I
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.8611111111111112] justify-center items-stretch px-3 py-3.5 border-solid border-zinc-300">
                        J
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        K
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9444444444444444] justify-center items-stretch px-3.5 py-3.5 border-solid border-zinc-300">
                        L
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-3.5 py-3.5 border-solid border-zinc-300">
                        M
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-4 py-3.5 border-solid border-zinc-300">
                        N
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-4 py-3 border-solid border-zinc-300">
                        O
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        P
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0555555555555556] justify-center items-stretch px-4 py-3 border-solid border-zinc-300">
                        Q
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0277777777777777] justify-center items-center h-[37px] px-4 border-solid border-zinc-300">
                        R
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        S
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9722222222222222] justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        T
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0277777777777777] justify-center items-center h-[37px] px-4 border-solid border-zinc-300">
                        U
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.0277777777777777] justify-center items-center h-[37px] px-3.5 border-solid border-zinc-300">
                        V
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[1.1944444444444444] justify-center items-stretch px-4 py-3.5 border-solid border-zinc-300">
                        W
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-square justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        X
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9722222222222222] justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        Y
                    </div>
                    <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white aspect-[0.9722222222222222] justify-center items-center h-9 px-3.5 border-solid border-zinc-300">
                        Z
                    </div>
                </div>
                <div className="text-cyan-600 text-center text-base leading-6 tracking-wide whitespace-nowrap border bg-white self-center aspect-[0.9722222222222222] justify-center items-center h-9 mt-2 px-3.5 rounded-none border-solid border-zinc-300">
                    #
                </div>
            </div>
           
           <div className='flex flex-wrap'>
           {
            storedta.map((val,i)=>(
                <Storecard img={val.img} key={i} offer={val.offers}/>
            ))
           }

           </div>

           <Footer/>

        </div>
    )
}

export default Mainstore