import React from 'react'
import Headernew from '../Components/Headernew'
import Footer from '../Components/Footer'

const Privacypolicy = () => {
  return (
    <div className="bg-white flex flex-col items-stretch">
      <Headernew/>
      <div className="bg-indigo-700 flex w-[1263px] shrink-0 max-w-full h-px flex-col mr-4 max-md:mr-2.5" />
      <div className="flex w-full flex-col mt-7 px-16 max-md:max-w-full max-md:px-5">
        <div className="text-zinc-800 text-4xl leading-10 self-stretch max-md:max-w-full">
          Privacy Policy
        </div>
        <div className="text-zinc-800 text-4xl leading-10 self-stretch mt-6 max-md:max-w-full">
          Privacy Policy for Cashpakar
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-5 mx-6 max-md:max-w-full max-md:mr-2.5">
          At CashPakar, accessible from www.cashpakar.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by CashPakar and how we use it.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </div>
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Log Files
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          CashPakar follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </div>
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Cookies and Web Beacons
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
          Like any other website, CashPakar uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </div>{" "}
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Google DoubleClick DART Cookie
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          Google is one of a third-party vendor on our site. It also uses
          cookies, known as DART cookies, to serve ads to our site visitors
          based upon their visit to www.website.com and other sites on the
          internet. However, visitors may choose to decline the use of DART
          cookies by visiting the Google ad and content network Privacy Policy
          at the following URL –
        </div>{" "}
        <div className="text-stone-500 text-sm leading-5 self-stretch mt-1.5 max-md:max-w-full">
          https://policies.google.com/technologies/ads
        </div>{" "}
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Our Advertising Partners
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 whitespace-nowrap ml-10 mt-4 self-start max-md:ml-2.5">
          Google
        </div>{" "}
        <div className="text-stone-500 text-sm leading-5 whitespace-nowrap ml-10 mt-4 self-start max-md:ml-2.5">
          https://policies.google.com/technologies/ads
        </div>{" "}
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Privacy Policies
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of CashPakar.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on CashPakar, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          Note that CashPakar has no access to or control over these cookies
          that are used by third-party advertisers.
        </div>
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Third Party Privacy Policies
        </div>
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          CashPakar's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options. You may find a complete list of these
          Privacy Policies and their links here: Privacy Policy Links.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch w-full mt-4 mx-4 max-md:max-w-full max-md:mr-2.5">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites. What Are Cookies?
        </div>
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-5 max-md:max-w-full">
          Children's Information
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-4 max-md:max-w-full">
          CashPakar does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </div>{" "}
        <div className="text-zinc-800 text-3xl leading-8 self-stretch mt-6 max-md:max-w-full">
          Online Privacy Policy Only
        </div>{" "}
        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-5 max-md:max-w-full">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in CashPakar. This policy is not applicable to
          any information collected offline or via channels other than this
          website.
        </div>
      </div>{" "}

      <Footer/>
     
    </div>
  )
}

export default Privacypolicy