import React from 'react'
import Footer from '../../../Components/Footer'
import Headernew from '../../../Components/Headernew'

const Viewstore = () => {
    return (
      
            <div className="bg-white flex flex-col ">
                <Headernew/>
                <div className="bg-indigo-700 self-stretch flex shrink-0 h-px flex-col max-md:max-w-full" />
                <div className="self-center w-full max-w-6xl mt-5 max-md:max-w-full">
                    <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                        <div className="flex flex-col items-stretch w-[26%] max-md:w-full max-md:ml-0">
                            <div className="flex grow flex-col items-stretch max-md:mt-4">
                                <div className="shadow-sm bg-white flex flex-col pb-9 px-2">
                                    <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702355134/opengraph_cg5euy.png' className="rounded  self-stretch flex shrink-0 h-[73px] flex-col" />
                                    <div className="bg-zinc-300 self-center flex w-[252px] shrink-0 max-w-full h-0.5 flex-col mt-2" />
                                    <div className="text-green-600 text-center text-base leading-6 capitalize mt-5">
                                        Flat 4000 Rs Cashback from Cashpakar
                                    </div>
                                    <div className="text-black text-center text-sm leading-5 capitalize self-center whitespace-nowrap mt-14 max-md:mt-10">
                                        Check Cashback Rate (scroll Down)
                                    </div>
                                    <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 self-center w-[226px] max-w-full justify-center items-center mt-8 px-16 py-3.5 max-md:px-5">
                                        Go To Store
                                    </div>
                                </div>
                                <div className="shadow-sm bg-white flex flex-col items-stretch mt-3.5 px-8 py-9 max-md:px-5">
                                    <div className="text-zinc-500 text-2xl leading-9">
                                        Cashback Status
                                    </div>
                                    <div className="bg-gray-300 flex shrink-0 h-px flex-col mt-5" />
                                    <div className="text-zinc-500 text-sm leading-5 mt-3.5">
                                        Tracking Speed
                                    </div>
                                    <div className="text-zinc-800 text-lg leading-6 mt-2">
                                        Jan 02, 2024
                                    </div>
                                    <div className="bg-gray-300 flex shrink-0 h-px flex-col mt-3.5" />
                                    <div className="text-zinc-500 text-sm leading-5 mt-3.5">
                                        Cashback Claim Time
                                    </div>
                                    <div className="text-zinc-800 text-lg leading-6 mt-3">
                                        Jan 30, 2024
                                    </div>
                                    <div className="bg-gray-300 flex shrink-0 h-px flex-col mt-4" />
                                    <div className="text-zinc-500 text-sm leading-5 mt-3.5">
                                        Estimated payment date
                                    </div>
                                    <div className="text-zinc-800 text-lg leading-6 mt-2">
                                        Feb 29, 2024
                                    </div>
                                    <div className="bg-gray-300 flex shrink-0 h-px flex-col mt-4" />
                                </div>
                                <div className="shadow-sm bg-white flex w-full flex-col mt-4 pl-8 pr-2.5 py-11 max-md:pl-5">
                                    <div className="text-zinc-800 text-center text-lg leading-5 capitalize self-center whitespace-nowrap">
                                        Rate Of A2hosting
                                    </div>
                                    <div className="text-white text-center text-2xl leading-9 whitespace-nowrap bg-green-600 self-stretch justify-center items-stretch mt-3.5 px-7 py-3 rounded-[50px] max-md:px-5">
                                        CASHBACK RATES
                                    </div>
                                    <div className="text-black text-center text-base leading-6 whitespace-nowrap border bg-white self-stretch justify-center items-stretch mt-2.5 px-9 py-2.5 rounded-[40px] border-solid border-green-800 max-md:px-5">
                                        Min Purchase 12 Months
                                    </div>
                                    <div className="text-black text-center text-base leading-6 whitespace-nowrap border bg-white self-stretch justify-center items-stretch mt-2.5 px-10 py-2.5 rounded-[40px] border-solid border-green-800 max-md:px-5">
                                        Order Track - 15 Minutes
                                    </div>
                                    <div className="self-stretch flex justify-between gap-5 mt-12 pr-6 items-start max-md:mt-10 max-md:pr-5">
                                        <div className="text-blue-700 text-base leading-6 mt-1">
                                            Web Hosting -
                                        </div>
                                        <div className="text-white text-center text-sm leading-5 whitespace-nowrap bg-indigo-700 self-stretch aspect-[2.2] justify-center items-stretch px-2 py-2.5 rounded-md">
                                            4000 RS
                                        </div>
                                    </div>
                                    <div className="text-zinc-800 text-lg leading-5 self-center whitespace-nowrap mt-20 max-md:mt-10">
                                        About us
                                    </div>
                                    <div className="text-zinc-800 text-sm leading-5 self-stretch mt-11 mb-11 max-md:my-10">
                                        A2 Hosting - The Fast Hosting Company You've Been Searching
                                        For Over the years, many customers have told us that they wish
                                        they would've found us sooner. We're glad you found us and are
                                        interested in learning more! We got our start back in 2001
                                        when our CEO Bryan Muthig used his strong background and
                                        experience as a UNIX systems administrator to launch A2
                                        Hosting. We've been independently owned ever since! While A2
                                        Hosting was originally launched as a hobby, it was evident
                                        this side venture would grow into something more. And it has!
                                        From our roots in a two-room office in Ann Arbor, Michigan to
                                        hand picking the very best team members and data centers
                                        located around the globe. From hosting a couple sites for a
                                        few friends and family members to hosting thousands of
                                        prominent websites. From just offering Shared Web Hosting to a
                                        complete, fully scalable product line featuring Reseller, VPS
                                        and Dedicated Server solutions.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-stretch w-[74%] ml-5 max-md:w-full max-md:ml-0">
                            <div className="flex flex-col max-md:max-w-full max-md:mt-4">
                                <div className="text-zinc-800 text-center text-xl leading-6 capitalize whitespace-nowrap shadow-sm bg-white self-stretch justify-center items-center px-16 py-5 max-md:max-w-full max-md:px-5">
                                    A2hosting coupon codes & deals
                                </div>
                                <div className="shadow-sm bg-white self-stretch flex w-full items-stretch justify-between gap-5 mt-5 pl-11 pr-20 py-5 max-md:max-w-full max-md:flex-wrap max-md:px-5">
                                    <div className="flex justify-between gap-0.5 items-start">
                                        <div className="text-black text-center text-sm leading-5 self-stretch grow whitespace-nowrap">
                                            All (
                                        </div>
                                        <div className="text-black text-center text-sm leading-5">
                                            1
                                        </div>
                                        <div className="text-black text-center text-sm leading-5 self-stretch whitespace-nowrap">
                                            )
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-0.5 items-start">
                                        <div className="text-cyan-600 text-center text-sm leading-5 self-stretch grow whitespace-nowrap">
                                            Coupons (
                                        </div>
                                        <div className="text-cyan-600 text-center text-sm leading-5">
                                            1
                                        </div>
                                        <div className="text-cyan-600 text-center text-sm leading-5 self-stretch whitespace-nowrap">
                                            )
                                        </div>
                                    </div>
                                    <div className="flex justify-between gap-0.5 items-start">
                                        <div className="text-cyan-600 text-center text-sm leading-5 self-stretch grow whitespace-nowrap">
                                            Deals (
                                        </div>
                                        <div className="text-cyan-600 text-center text-sm leading-5">
                                            0
                                        </div>
                                        <div className="text-cyan-600 text-center text-sm leading-5 self-stretch whitespace-nowrap">
                                            )
                                        </div>
                                    </div>
                                </div>
                                <div className="self-stretch flex justify-between gap-0 pr-20 items-start max-md:max-w-full max-md:flex-wrap max-md:pr-5">
                                    <div className="bg-zinc-300 flex shrink-0 h-px flex-col max-md:max-w-full" />
                                    <div className="bg-indigo-500 self-stretch flex w-[125px] shrink-0 h-0.5 flex-col" />
                                </div>
                                <div className="flex gap-1 self-start items-start">
                                    <div className="text-zinc-800 text-sm leading-5 grow whitespace-nowrap">
                                        Showing
                                    </div>
                                    <div className="text-zinc-800 text-sm leading-5">1-1</div>
                                    <div className="text-zinc-800 text-sm leading-5">of</div>
                                    <div className="text-zinc-800 text-sm leading-5">1</div>
                                    <div className="text-zinc-800 text-sm leading-5 grow whitespace-nowrap">
                                        item.
                                    </div>
                                </div>
                                <div className="shadow-sm bg-white self-stretch flex justify-between gap-5 mt-3 pl-11 pr-4 pb-6 rounded-md items-start max-md:max-w-full max-md:flex-wrap max-md:pl-5">
                                    <img src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702355134/opengraph_cg5euy.png' className=" flex w-[124px] shrink-0 h-[33px] flex-col mt-8" />
                                    <div className="bg-stone-300 flex w-1 shrink-0 h-[113px] flex-col" />
                                    <div className="flex grow basis-[0%] flex-col mt-3 max-md:max-w-full">
                                        <div className="self-stretch flex justify-between gap-5 items-start max-md:max-w-full max-md:flex-wrap">
                                            <div className="text-blue-700 text-xl leading-5 capitalize grow shrink basis-auto">
                                                Web Hosting 1 Year @51% One Time Discount + 4000 RS CP
                                                CashBack
                                            </div>
                                            <div className="text-black text-center text-sm leading-5 uppercase whitespace-nowrap bg-yellow-400 grow justify-center items-stretch mt-1.5 px-10 py-3.5 max-md:px-5">
                                                SHOW COUPON
                                            </div>
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-6 max-md:max-w-full">
                                            1. click on the show coupon and you are redirected on
                                            a2hosting website
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3.5 max-md:max-w-full">
                                            2. scroll down and check your hosting requirement (SHARED
                                            HOSTING, RESELLER HOSTING, VPS HOSTING, DEDICATED SERVER)
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            3. click on get started and check with your domain name if
                                            you have , otherwise register a new domain
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            4. select your plan at least 12 months for getting cashback
                                        </div>
                                        <div className="text-blue-700 text-lg leading-5 whitespace-nowrap ml-28 mt-3 self-start max-md:ml-2.5">
                                            Terms & Conditions
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-4 max-md:max-w-full">
                                            1. You have a valid domain name.
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3.5 max-md:max-w-full">
                                            2.You will have to active and daily updates on your website
                                            until you get your cashback
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            3. Cashback eligible for per customer per ip address
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            4. you must have to active on your hosting until you get
                                            your cashback
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            5.Not Valid for A2 Hosting App transactions
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3 max-md:max-w-full">
                                            6. cashback track within 15 min
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-3.5 max-md:max-w-full">
                                            7. fraudulent is not allowed
                                        </div>
                                        <div className="text-zinc-800 text-xs leading-3 self-stretch mt-4 max-md:max-w-full">
                                            8. if you have any issues please Whatsapp on +918602490161
                                        </div>
                                        <div className="text-zinc-800 text-sm leading-5 self-stretch mt-6 max-md:max-w-full">
                                            .
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
                
            </div>
        
    )
}

export default Viewstore