import logo from './logo.svg';
import './App.css';
import Mainhome from './Pages/Home/Mainhome';
import Testmainhom from './Pages/Home/Testmainhom';
import './globle.css'
import {  BrowserRouter as Router,
  Routes, Route} from 'react-router-dom';
import Mainroutesuser from './Pages/Users/Routes/Mainroutesuser';

function App() {
  return (
    // <div className="App">
    //   <Mainhome/>
    //   {/* <Testmainhom/> */}
    // </div>

    <Router>

      <Routes>

      <Route path='/*' element={<Mainroutesuser/>}/>


      </Routes>
    </Router>
  );
}

export default App;
