import React, { useState } from 'react'

const Storecard = ({offer,img}) => {

    const [show, setshow] = useState(false)



    return (
        <div onMouseOut={() => setshow(false)} onMouseOver={() => setshow(true)} className=" bg-white shadow-md rounded-sm m-5  gap-0 w-2/6 md:w-1/6">
            <div className={`text-white  bg-pink-600 ${show ? 'block' : 'hidden'} flex justify-center items-center rounded-sm w-full  h-[110px]`}>
                {offer} offers
            </div>
            <div className={`shadow-sm  ${show ? 'hidden' : 'block'} self-stretch flex rounded-sm grow basis-[0%] justify-center  items-center pb-12 px-4  `} >
            <img src={img} className="m-auto flex shrink-0 h-[60px]" />

            </div>

        </div>
    )
}

export default Storecard