import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Headernew = () => {

    const navigate= useNavigate()

    const [open, setopen] = useState(false)

    const handleclick = () => {
        setopen(!open)
    }

    const handlelogoclk = ()=>{

        navigate('/')
        window.location.reload()
        
    }
    return (
        <div className="shadow-sm  bg-indigo-700 self-stretch grid grid-cols-4  w-full items-start justify-between gap-5 pt-2 pb-0.5 px-16 max-md:max-w-full max-md:flex-wrap max-md:px-5">
            <img  onClick={handlelogoclk} src='https://res.cloudinary.com/dhiare7bl/image/upload/v1702288659/cashpakar/logo-1572932951-1578020354_26a8accd21b0cb58a3d37ed8b9ace9b9-1597022431_umaggg.png' className=" cursor-pointer self-center flex w-[150px] shrink-0 h-6 flex-col my-auto col-span-3 md:col-span-1" />

            <div onClick={handleclick} className='flex justify-end col-span-1  md:hidden'>
                {
                    open ? <ion-icon onClick={handleclick} style={{color:'white'}} name="close-outline" className="close text-white"></ion-icon> : <ion-icon style={{color:'white'}} onClick={handleclick} name="menu-outline" className="open"></ion-icon>
                }
            </div>

            <div className={`${open ? 'block col-span-4' : 'hidden col-span-3'}   md:flex justify-between md:col-span-3`}>
                <div className="self-center md:col-span-2 flex items-stretch gap-2 my-auto max-md:justify-center">
                    <a href='/all-store' className="text-white cursor-pointer font-medium gap-2 text-sm leading-5 whitespace-nowrap self-start">
                        All Stores
                    </a>
                    <a href='/all-store' className="text-white text-sm gap-2 cursor-pointer ml-3 font-medium leading-5">Gift Cards</a>
                    <div className="text-white gap-4 font-medium cursor-pointer ml-3 text-sm leading-5 whitespace-nowrap self-start">
                        How it works?
                    </div>
                </div>
                <div className="self-stretch flex items-stretch m-2 md:m-0 gap-0 justify-center">
                    <input type='text' placeholder='  Search here' className="rounded bg-white flex w-[300px] shrink-0 h-10 flex-col" />
                    {/* <div className="border bg-yellow-400 flex w-10 shrink-0 h-3.5 flex-col mt-3 rounded-none border-solid border-stone-300 self-start" /> */}
                </div>
                <div className="self-center flex items-stretch justify-between gap-5 my-auto">
                    <a href='/login' className="text-white text-sm leading-5 whitespace-nowrap font-medium cursor-pointer ml-3">
                        Signin
                    </a>
                    <a href='/register' className="text-white text-sm leading-5 font-medium cursor-pointer ml-3 whitespace-nowrap self-start">
                        Join
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Headernew