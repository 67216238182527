import React from 'react'
import Headernew from '../Components/Headernew'

const Aboutus = () => {
  return (
    <div className="bg-white flex flex-col items-stretch">

        <Headernew/>
    
    <div className="bg-indigo-700 flex w-[1263px] shrink-0 max-w-full h-px flex-col mr-4 max-md:mr-2.5" />
    <div className="flex w-full flex-col items-stretch mt-7 px-16 max-md:max-w-full max-md:px-5">
      <div className="text-zinc-800 text-4xl leading-10 max-md:max-w-full">
        About Us
      </div>
      <div className="text-zinc-800 text-sm leading-5 mt-7 max-md:max-w-full">
        Founded by Pradeep Singh & Manish Singh in November 2019 Cashpakar.com
        is india's Biggest Coupons&Cashback Site or Gift Vochers Cashpakr is a
        one destination for Saveings 1500+ Online Shopping Site Partners With
        Cashpakar like - Amazon,Paytm,Flipkart,Myntra,Jabong Or 1500+
        Merchants
      </div>{" "}
      <div className="text-zinc-800 text-sm leading-5 mt-4 max-md:max-w-full">
        Cashpakar office in Gwalior (MP)
      </div>{" "}
      <div className="text-zinc-800 text-sm leading-5 mt-12 max-md:max-w-full max-md:mt-10">
        CashPakar Is Best Coupon and Deals And Cashback Or Gift Card Website
        like- Paytm ,Amazon, Flipkart
      </div>{" "}
      <div className="text-zinc-800 text-sm leading-5 mt-4 max-md:max-w-full">
        CashPakar Has Partner With 1500+ Marchants like
        Amazon,paytm,flipkart,bata much more
      </div>{" "}
      <div className="text-zinc-800 text-sm leading-5 mt-4 max-md:max-w-full">
        You chosise your favorite shopping site via cashpakar and get extra
        cashback in cashpakar wallet
      </div>{" "}
      <div className="text-zinc-800 text-sm leading-5 mt-4 max-md:max-w-full">
        cashpakar money is real money you can transfer your money in your
        paytm ,bank ,gift cards
      </div>
    </div>{" "}
    <div className="bg-neutral-800 flex w-[1263px] max-w-full justify-between gap-5 mr-4 mt-11 pl-16 pr-20 pt-7 pb-12 items-start max-md:flex-wrap max-md:mr-2.5 max-md:mt-10 max-md:px-5">
      <div className="flex grow basis-[0%] flex-col items-start">
        <div className="text-white text-sm leading-4 self-stretch">
          Cashpakar
        </div>{" "}
        <div className="bg-slate-400 flex w-[72px] shrink-0 h-px flex-col mt-7" />{" "}
        <div className="text-white text-sm leading-5 self-stretch mt-6">
          Cashpakar is that platform where you can earn extra cashback when
          you shop through cashpakar on 250+ shopping site in India.
        </div>
      </div>{" "}
      <div className="flex basis-[0%] flex-col items-stretch">
        <div className="text-white text-sm leading-4">Top Stores</div>{" "}
        <div className="bg-slate-400 flex shrink-0 h-px flex-col mt-7" />{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-6">
          PowerPlay Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
          BetandYou Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">
          JeetPlay Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 mt-2">
          BetoBet Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-2.5">
          CaptainsBet Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
          OhMySpins Casino
        </div>{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
          CampeonBet Casino
        </div>
      </div>{" "}
      <div className="flex basis-[0%] flex-col items-stretch">
        <div className="text-white text-sm leading-4">Usefull Links</div>{" "}
        <div className="bg-slate-400 flex shrink-0 h-px flex-col mt-8" />{" "}
        <div className="text-white text-sm leading-5 mt-6">About Us</div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">
          Privacy Policy
        </div>{" "}
        <div className="text-white text-sm leading-5 whitespace-nowrap mt-2">
          Terms and Conditions
        </div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">FAQ</div>{" "}
        <div className="text-white text-sm leading-5 mt-2">How it Work</div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">
          Cashback Releted
        </div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">
          Refer And Earn
        </div>{" "}
        <div className="text-white text-sm leading-5 mt-2.5">
          Gwalax Course
        </div>
      </div>{" "}
      <div className="flex basis-[0%] flex-col items-stretch">
        <div className="text-white text-sm leading-4">Contact Us</div>{" "}
        <div className="bg-slate-400 flex shrink-0 h-px flex-col mt-8" />{" "}
        <div className="text-cyan-600 text-sm leading-5 mt-7">
          Telegram - @Cashpakar_Support
        </div>{" "}
        <div className="text-cyan-600 text-sm leading-5 whitespace-nowrap mt-4">
          info@cashpakar.com
        </div>{" "}
        <div className="flex items-stretch justify-between gap-1 mt-3.5 pr-2">
          <div className="border flex aspect-square flex-col justify-center items-center w-[30px] h-[30px] border-solid border-neutral-800">
            <div className="bg-zinc-300 z-[1] flex shrink-0 h-[30px] flex-col" />
          </div>{" "}
          <div className="border flex aspect-square flex-col justify-center items-center w-[30px] h-[30px] px-px border-solid border-neutral-800">
            <div className="bg-zinc-300 z-[1] flex shrink-0 h-[30px] flex-col" />
          </div>{" "}
          <div className="border flex aspect-square flex-col justify-center items-center w-[30px] h-[30px] px-px border-solid border-neutral-800">
            <div className="bg-zinc-300 z-[1] flex shrink-0 h-[30px] flex-col" />
          </div>{" "}
          <div className="border flex aspect-square flex-col justify-center items-center w-[30px] h-[30px] border-solid border-neutral-800">
            <div className="bg-zinc-300 z-[1] flex shrink-0 h-[30px] flex-col" />
          </div>
        </div>
      </div>
   
  </div>
  </div>
  )
}

export default Aboutus